nav {
  padding: 40px 0;
}
.nav_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav_wrapper_icon {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav_wrapper_icon h2 {
  color: rgb(0, 0, 0);

  font-size: 20px;
  font-weight: 800;
  line-height: 78%;
  letter-spacing: 1%;
  text-align: left;
}
.nav_wrapper_icon p,
.nav_wrapper_menu p {
  color: rgb(0, 0, 0);

  font-size: 14px;
  font-weight: 400;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
}

.nav_wrapper_menu {
  display: flex;
  align-items: center;
  gap: 34px;
}

.nav_wrapper_login {
  display: flex;
  align-items: center;
}
.nav_wrapper_login button {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
  background: transparent;
  padding: 10px 26px;
}
.nav_wrapper_login a:nth-child(2) button {
  border-radius: 10px;
  padding: 10px 40px 10px 40px;
  background: rgb(255, 218, 59);
}

.nav_wrapper_right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav_lang_toggler {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav_lang_toggler div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.nav_lang_toggler div p {
  color: rgb(0, 0, 0);

  font-size: 10.76px;
  font-weight: 600;
  line-height: 78%;
  letter-spacing: 1%;
  text-align: left;
}
.nav_burger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav_burger {
  display: none;
}
.nav_burger svg {
  cursor: pointer;
}

.mobile_nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 111;
  background: #fff;
  padding: 30px 20px;
  transform: translateX(100%);
  transition: all 0.4s ease;
}
.visible_mobile_nav {
  transform: translateX(0%);
  transition: all 0.4s ease;
}
.mobile_nav .nav_wrapper_menu {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  margin-top: 50px;
}
.mobile_nav .nav_wrapper_menu p {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1%;
  text-align: left;
}
.mobile_nav_toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav_user_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav_user_profile img {
  width: 33px;
  border-radius: 50%;
  object-fit: cover;
}
.nav_user_profile h4 {
  font-size: 14px;
  color: #000;
}
.nav_user_profile p {
  font-size: 12px;
  color: grey;
}

@media only screen and (max-width: 1080px) {
  .nav_wrapper_icon p {
    display: none;
  }
}
@media only screen and (max-width: 930px) {
  .nav_wrapper_icon img {
    display: none;
  }
  .nav_wrapper .nav_wrapper_menu,
  .nav_wrapper .nav_lang_toggler,
  .nav_wrapper_login a:nth-child(2) button {
    display: none;
  }
  .nav_burger {
    display: flex;
  }
}

@media only screen and (max-width: 420px) {
  nav {
    padding: 30px 0 50px;
  }
}
