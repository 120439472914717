.snackbar_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.snackbar {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 11;

  border-radius: 6px;
  background: var(--Color_White, #fff);
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  /*  */
  visibility: hidden;
  transform: translateY(100%);
  transition: all 0.5s ease;
  opacity: 0;
}
.snackbar p {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.snackbar_visible {
  transform: translateY(0%);
  transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;
}
.snackbar_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 620px) {
  .snackbar_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .snackbar {
    top: 24px;
    left: 24px;
    right: 24px;
  }
}
