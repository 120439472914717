@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
}

input,
button,
textarea,
select {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media only screen and (max-width: 1230px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
