footer {
  width: 100%;
  margin-top: 100px;
}
.footer_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_left {
  width: 60%;
  background: rgb(255, 140, 140);
  height: 473px;
  padding: 50px 160px 50px 0;
  display: flex;
  align-items: start;
  justify-content: end;
  gap: 217px;
}
.footer_right {
  background: rgb(255, 218, 59);
  height: 473px;
  width: 40%;
  padding: 50px 40px 0 50px;
}

.footer_left_menu_list p {
  color: rgb(255, 255, 255);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
  margin-bottom: 17px;
}

.footer_right_text h2 {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 21px;
  font-weight: 600;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
  margin-bottom: 20px;
}
.footer_right_text p {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
  margin-bottom: 30px;
  max-width: 269px;
}

.footer_right_payments {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 50px;
}
.footer_right_payments img {
  cursor: pointer;
}
.footer_policy_socials {
  display: flex;
  align-items: flex-end;
  gap: 80px;
}
.footer_policy p:nth-child(1) {
  margin-bottom: 10px;
}
.footer_socials {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.footer_socials img {
  cursor: pointer;
}

@media only screen and (max-width: 1260px) {
  .footer_left {
    padding: 50px;
    padding-left: 20px;
    justify-content: space-between;
    gap: 20px;
  }
}

@media only screen and (max-width: 890px) {
  .footer_wrapper {
    flex-direction: column;
  }
  .footer_left,
  .footer_right {
    width: 100%;
  }
  .footer_left,
  .footer_right {
    padding: 30px 20px 50px 20px;
    height: auto;
  }
}

@media only screen and (max-width: 420px) {
  .footer_left_menu_list p {
    color: rgb(255, 255, 255);
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 121.9%;
    letter-spacing: 1%;
    text-align: left;
  }
  .footer_left {
    padding-bottom: 13px;
  }
  .footer_policy p {
    font-size: 12px !important;
  }
  .footer_policy_socials {
    justify-content: space-between;
  }
}
