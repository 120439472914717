.login_page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;
}

.login_left_logo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.login_left_logo img {
  width: 45px;
}
.login_left_logo h1 {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 78%;
  letter-spacing: 1%;
  text-align: left;
}

.login_left h2 {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 30px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 1%;
  text-align: center;
  margin-bottom: 30px;
}

.login_left p {
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 17px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1%;
  text-align: center;
  margin-bottom: 40px;
}
.login_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_left img:nth-child(4) {
  width: 300px;
}
.login_right {
  border-radius: 25px;
  padding: 30px;
  width: 480px;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);
}

.login_right_togglers {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 30px;
}

.login_right_togglers p {
  color: rgb(122, 122, 122);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}

.login_right_toggler_active {
  color: rgb(0, 0, 0) !important;

  font-size: 22px !important;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0%;
  text-align: left;
}

.login_tg {
  border-radius: 20px;
  height: 50px;
  width: 100%;
  background: rgb(85, 165, 228);
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  margin-bottom: 30px;
  cursor: pointer;
}

.login_tg p {
  color: rgb(255, 255, 255);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0%;
  text-align: center;
}

.login_right_line {
  width: 100%;
  height: 1px;
  background: rgba(163, 163, 163, 0.705);
  margin-bottom: 30px;
}

.login_right_desc p {
  color: rgb(163, 163, 163);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0%;
  margin-bottom: 30px;
}
.login_form_input {
  margin-bottom: 15px;
  position: relative;
}
.login_form_input input {
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
  width: 100%;
  height: 50px;
  background: rgb(255, 255, 255);
  text-indent: 15px;

  color: #000;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 121.9%;
  letter-spacing: 1%;
  text-align: left;
}
.login_form_input input::placeholder {
  color: rgb(176, 176, 176);
}

.login_form_input img {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.login_btn button {
  border-radius: 10px;
  width: 100%;
  background: rgb(255, 218, 59);
  color: rgb(0, 0, 0);
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0%;
  text-align: center;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.login_form_check {
  display: flex;
  align-items: center;
  gap: 20px;
}
.login_form_check_input {
  border-radius: 4px;
  cursor: pointer;
  background: rgb(255, 218, 59);
  padding: 5px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  justify-content: center;
}

.login_form_check p {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
}
.login_form_check a {
  color: rgb(253, 21, 72);
}

.login_form_check p {
  max-width: 360px;
}

.login_right_togglers p {
  cursor: pointer;
}
@media only screen and (max-width: 980px) {
  .login_page {
    flex-direction: column-reverse;
    gap: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .login_right {
    width: 100%;
  }
}
@media only screen and (max-width: 430px) {
  .login_right {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}